<template>
  <div class="veterans">
    <van-form @submit="onSubmit" validateTrigger="onSubmit">
      <van-field
        v-model="info.realName"
        name="realName"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true }]"
      >
        <template #left-icon>
          <van-icon name="user-o" />
        </template>
      </van-field>
      <van-field
        name="sex"
        label="性别"
        :rules="[{ required: true, message: '请选择性别' }]"
      >
        <template #left-icon>
          <van-icon name="friends-o" />
        </template>
        <template #input>
          <van-radio-group v-model="info.sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="info.account"
        name="account"
        label="身份证号"
        placeholder="身份证号"
        :rules="[{ validator: isCardNo, message: '请输入正确身份证号' }]"
      >
        <template #left-icon>
          <van-icon name="description" />
        </template>
      </van-field>
      <van-field
        v-model="info.phone"
        name="phone"
        label="手机号"
        placeholder="手机号"
        :rules="[
          {
            validator: isMobileNotRequired,
            message: '请输入正确手机号',
            required: true,
            message: '请输入手机号',
          },
        ]"
      >
        <template #left-icon>
          <van-icon name="phone-o" />
        </template>
      </van-field>

      <van-field
        v-model="info.remark"
        name="备注"
        label="备注"
        placeholder="备注"
      >
        <template #left-icon>
          <van-icon name="records" />
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
// import { levelList } from "@/api/registerApi"
import { submitInfo } from "@/api/userApi";
import { isMobileNotRequired, isCardNo } from "@/utils/validate";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      showPicker1: false,
      showPicker2: false,
      shequList: [],
      xiaoquList: [],
      info: {
        openid: this.$store.getters.openId,
        shequId: "",
        xiaoquId: "",
        shequName: "",
        xiaoquName: "",
        realName: "",
        account: "",
        phone: "",
        remark: "",
        sex: "0",
      },
    };
  },
  // 计算属性
  computed: {},

  // watch
  watch: {
    aaa: {
      //深度监听，可监听到对象、数组的变化
      handler(val) {
        this.aaa = val;
      },
      deep: true,
    },
  },

  created() {
    // this.getLevelList()
    //   this.$router.push({path: '/activityList'})
  },
  methods: {
    isMobileNotRequired: isMobileNotRequired,
    isCardNo: isCardNo,
    
    onSubmit() {
      submitInfo({
        openid: this.$store.getters.openId,
        streetId: this.info.shequId,
        estateId: this.info.xiaoquId,
        realName: this.info.realName,
        sex: this.info.sex,
        account: this.info.account,
        idCard: this.info.account, //接口问题需要
        phone: this.info.phone,
        tenantId: this.$store.getters.tenantId,
        avatar: this.$store.state.wxInfo
          ? this.$store.state.wxInfo.headImgUrl
          : "",
        remark: this.info.remark,
      }).then(() => {
        this.$store.dispatch("GetToken").then(() => {
          this.$toast({
            type: "success",
            message: "提交成功",
            duration: 500,
            onClose: function () {
              window.location.href = "/home";
            },
          });
        });
      });
    },
  },
};
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
.veterans {
  background: #f3f4f6;
  h3 {
    color: #8d8c8c;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
  }
  .joinArmyTime-action,
  .leaveArmyTime-action {
    position: absolute;
    left: 0%;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
}
</style>
