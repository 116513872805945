var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"veterans"},[_c('van-form',{attrs:{"validateTrigger":"onSubmit"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"realName","label":"姓名","placeholder":"姓名","rules":[{ required: true }]},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"name":"user-o"}})]},proxy:true}]),model:{value:(_vm.info.realName),callback:function ($$v) {_vm.$set(_vm.info, "realName", $$v)},expression:"info.realName"}}),_c('van-field',{attrs:{"name":"sex","label":"性别","rules":[{ required: true, message: '请选择性别' }]},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"name":"friends-o"}})]},proxy:true},{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.info.sex),callback:function ($$v) {_vm.$set(_vm.info, "sex", $$v)},expression:"info.sex"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("男")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("女")])],1)]},proxy:true}])}),_c('van-field',{attrs:{"name":"account","label":"身份证号","placeholder":"身份证号","rules":[{ validator: _vm.isCardNo, message: '请输入正确身份证号' }]},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"name":"description"}})]},proxy:true}]),model:{value:(_vm.info.account),callback:function ($$v) {_vm.$set(_vm.info, "account", $$v)},expression:"info.account"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"手机号","rules":[
        {
          validator: _vm.isMobileNotRequired,
          message: '请输入正确手机号',
          required: true,
          message: '请输入手机号',
        },
      ]},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"name":"phone-o"}})]},proxy:true}]),model:{value:(_vm.info.phone),callback:function ($$v) {_vm.$set(_vm.info, "phone", $$v)},expression:"info.phone"}}),_c('van-field',{attrs:{"name":"备注","label":"备注","placeholder":"备注"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"name":"records"}})]},proxy:true}]),model:{value:(_vm.info.remark),callback:function ($$v) {_vm.$set(_vm.info, "remark", $$v)},expression:"info.remark"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }